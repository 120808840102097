
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import CategoryExhibitionListView from '../../views/category/CategoryExhibitionList.vue'
import CategoryHeader from '../../views/category/common/CategoryHeader.vue'
import FlipBanner from '@/components/common/layout/FlipBanner.vue'
export default Vue.extend<Data, Methods, Computed>({
  name: 'CategoryExhibitionList',
  components: {
    CategoryHeader,
    CategoryExhibitionListView,
    FlipBanner,
  },
  data() {
    return {
      share: true,
      shareItem: {
        title: '',
        description: '',
        imageUrl: '',
        url: '',
        previousPage: '',
      },
      copyUrlProps: {
        promotionNo: 0,
        categoryName: '',
        description: '',
        copyUrl: '',
      },
      active: null,
      initIndex: 0,
      initPromotionNo: 0,
      initScroll: 0,
      showList: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    if (
      sessionStorage.getItem('CelltiqueInfo') &&
      from.name &&
      from.name !== 'StoreHome'
    ) {
      sessionStorage.removeItem('CelltiqueInfo')
    }
    next()
  },
  async created() {
    this.showList = false
    const storage = JSON.parse(
      sessionStorage.getItem('CelltiqueInfo') ?? 'null'
    )

    const promotionNumber =
      (storage && storage.promotionNo) ?? Number(this.$route.query.promotionNo)

    try {
      await this.fetchCategoryPlanex()
      const idx = this.getCelltiques.findIndex(
        (item) => item.promotionNo === promotionNumber
      )

      this.initIndex = idx
      this.initPromotionNo = promotionNumber
      this.initScroll = (storage && storage.scroll) ?? 0
    } catch (e) {
      console.log(e)
    } finally {
      this.showList = true
    }
  },
  computed: {
    ...mapState('BannerStore', ['onBanner']),
    ...mapGetters('CelltiqueStore', ['getCelltiques']),
    headerAnimClass(): string {
      return this.onBanner ? 'common-header-visible' : 'common-header-animate'
    },
  },
  methods: {
    ...mapActions('CelltiqueStore', [
      'fetchCategoryPlanex',
      'fetchCategoryPlanexDetail',
    ]),

    onClickTriangle(share: boolean) {
      this.share = share
    },
    excuteSwipe({ title, description, icon, promotionNo }: CategoryPlanex) {
      this.shareItem.title = title.replaceAll('\n', ' ')
      this.shareItem.description = description
      this.shareItem.imageUrl = icon
      this.shareItem.url =
        window.location.origin + '/?' + 'exhibitionNo=' + promotionNo
      this.copyUrlProps.promotionNo = promotionNo
      this.copyUrlProps.categoryName = title
      this.copyUrlProps.copyUrl =
        window.location.origin + '/?' + 'exhibitionNo=' + promotionNo
    },
    onClickPrev() {
      this.$tracking(`planning_${this.initPromotionNo}_back`)
    },
    openShare() {
      this.$tracking(`planning_${this.initPromotionNo}_share`)
    },
    closeShare(type: string) {
      if (type) {
        this.$tracking(`planning_${this.initPromotionNo}_share_${type}`)
      }
    },
  },
})

interface Data {
  active: number | null
  share: boolean
  shareItem: {
    title: string
    description: string
    imageUrl: string
    url: string
    previousPage: string
  }
  copyUrlProps: {
    promotionNo: number
    categoryName: string
    description: string
    copyUrl: string
  }
  initIndex: number
  initPromotionNo: number
  initScroll: number
  showList: boolean
}
interface Methods {
  // Store
  fetchCategoryPlanex(): Promise<void>
  fetchCategoryPlanexDetail(payload: number): Promise<void>
  // Component
  onClickTriangle(share: boolean): void
  excuteSwipe(data: CategoryPlanex): void
  onClickPrev(): void
  openShare(): void
  closeShare(type: string): void
}
interface Computed {
  onBanner: boolean
  getCelltiques: CategoryPlanex[]
  headerAnimClass: string
}
