
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import PullToRefresh from '@/components/common/pullToRefresh/PullToRefresh.vue'
import { onRequestAdonBanner } from '@/helper/banner'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'

// 스켈레톤 로딩이 300ms 지나서 나타남
const DEFERRED_TIME = 300

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryExhibitionListView',
  components: {
    ProductCardY,
    PullToRefresh,
  },
  props: {
    initIndex: {
      type: Number,
      required: true,
    },
    initPromotionNo: {
      type: Number,
      required: true,
    },
    initScroll: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('CelltiqueStore', ['getCelltiques', 'getCelltiqueDetail']),
    ...mapState('CommonStore', ['scrollTopNoti']),
    ...mapState('DeviceStore', ['deviceInfo']),
  },
  watch: {
    isLogined() {
      this.handleRefresh()
    },
    scrollTopNoti() {
      this.$tracking(`planning_${this.currrentPromotionNo}_go_to_top`)
    },
  },
  data() {
    return {
      touchless: false,
      GAData: {
        title: '',
        location: `${process.env.VUE_APP_CELLOOK_URL}/categoryExhibitionList`,
        path: '/categoryExhibitionList',
      },
      carouselIndex: 0,
      currrentPromotionNo: 0,
      pageLoaded: false,
      productLoaded: false,
      productSkeletonTimer: false,
      pageSkeletonTimer: false,
    }
  },
  async created() {
    let timer

    try {
      this.carouselIndex = this.initIndex
      this.currrentPromotionNo = this.initPromotionNo

      timer = setTimeout(() => {
        this.pageSkeletonTimer = true
      }, DEFERRED_TIME)

      await this.fetchCategoryPlanexDetail(this.initPromotionNo)

      // 로딩 완료
      this.pageLoaded = true
      this.productLoaded = true

      // 공유하기 값들 초기화
      this.$emit('excuteSwipe', this.getCelltiqueDetail.planexCategoryTitle)

      // 스크롤 리포지션
      this.$nextTick(() => {
        this.$getAppHtml.scrollTo({
          top: this.initScroll,
        })

        if (sessionStorage.getItem('CelltiqueInfo')) {
          sessionStorage.removeItem('CelltiqueInfo')
        }
      })
    } catch (e) {
      console.log(e)
      this.$router.push('/')
    } finally {
      this.GAData.title = `planning_${this.currrentPromotionNo}`
      this.$trackingPromotion(this.GAData)

      clearTimeout(timer)
      this.pageSkeletonTimer = false
    }
  },

  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('CelltiqueStore', ['fetchCategoryPlanexDetail']),
    ...mapActions('HomeStore', ['fetchMainTab']),
    pageMoveMDsPick() {
      this.$tracking(`planning_${this.currrentPromotionNo}_mdpick`)
      this.fetchMainTab({ tab: 3 })
      this.$router.push('/')
    },
    pullToState(yn) {
      this.touchless = yn
    },
    async handleRefresh() {
      try {
        this.fetchLoading({ yn: true })
        if (this.$route.name) onRequestAdonBanner(this.$route.name)

        await this.fetchCategoryPlanexDetail(this.currrentPromotionNo)
      } catch (e) {
        console.log(e)
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
    async onChangeCarousel(index: number) {
      let timer

      try {
        this.productLoaded = false

        timer = setTimeout(() => {
          this.productSkeletonTimer = true
        }, DEFERRED_TIME)

        const { promotionNo } = this.getCelltiques[index]

        this.currrentPromotionNo = promotionNo
        this.carouselIndex = index

        this.$emit('excuteSwipe', this.getCelltiques[index])
        await this.fetchCategoryPlanexDetail(promotionNo)
        this.productLoaded = true

        this.GAData.title = `planning_${this.currrentPromotionNo}`
        this.$trackingPromotion(this.GAData)
      } catch (e) {
        console.log(e)
      } finally {
        clearTimeout(timer)
        this.productSkeletonTimer = false
      }
    },
    setScrollAndPromotionNo() {
      sessionStorage.setItem(
        'CelltiqueInfo',
        JSON.stringify({
          scroll: this.$getAppHtml.scrollTop,
          promotionNo: this.currrentPromotionNo,
        })
      )
    },
    clickProduct() {
      if (this.deviceInfo) return
      this.setScrollAndPromotionNo()
    },
  },
})

interface Props {
  initIndex: number
  initPromotionNo: number
  initScroll: number
}

interface Data {
  pageLoaded: boolean
  productLoaded: boolean
  touchless: boolean
  GAData: {
    title: string
    location: string
    path: string
  }
  carouselIndex: number
  currrentPromotionNo: number
  productSkeletonTimer: boolean
  pageSkeletonTimer: boolean
}
interface Methods {
  //Store
  fetchLoading(paylod: { yn: boolean }): Promise<void>
  fetchCategoryPlanexDetail(payload: number): Promise<void>
  fetchMainTab(payload: { tab: number }): Promise<void>
  // Component
  pageMoveMDsPick(): void
  pullToState(yn: boolean): void
  handleRefresh(): void
  onChangeCarousel(index: number): void
  clickProduct(): void
  setScrollAndPromotionNo(): void
}
interface Computed {
  getCelltiques: CategoryPlanex[]
  getCelltiqueDetail: {
    planexCategoryDetailList: Array<{
      title: string
      searchProductList: ProductItem[]
    }>
    planexCategoryTitle: CategoryPlanex
  }
  scrollTopNoti: boolean
  deviceInfo: DeviceInfo
}
